.scatter-plot-3d-form {
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media screen and (max-width: 1080px) {
	.scatter-plot-3d-form {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}
@media screen and (max-width: 800px) {
	.scatter-plot-3d-form {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}
