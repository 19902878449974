.base-input-constraints-table{
  user-select: none;
  .ant-table-thead{
    .ant-table-cell{
        padding: 12px !important;
        border-right: 0px !important;
      }
  }
  .ant-table-tbody{
    .ant-table-cell{
        padding: 12px !important;
        border-right: 0px !important;
      }
  }
}