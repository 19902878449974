.infinite-table {
    border-collapse: collapse;
    // width: 100%;
}

.infinite-table td,
 .infinite-table th {
    padding: 8px;
}

.infinite-table tr {
    background-color: white;
}

.infinite-table tr:hover {
    background-color: #f8f8f8;
}

.infinite-table-header {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f8f8f8;
    color: rgb(0, 0, 0);
    position: sticky;
    top: 0;
    z-index: 2;
    border-right: 1px solid #ccc;
}

.table-icons {
    cursor: "pointer";
}

.table-data-center {
    text-align: center;
}

.formulation-list:hover {
    text-decoration: underline;
}

.animated__hover__btn .ant-btn-icon {
    margin-inline-end: 0px !important;
    transition: margin-inline-end 0.5s
}

.animated__hover__btn{
    padding: 4px 10px !important
}

.animated__hover__btn .animated__hover__content {
    max-width: 0;
    transition: max-width 0.5s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .animated__hover__btn:hover .animated__hover__content {
    max-width: 25rem;
    transition-delay: 0.1s;
  }

  .animated__hover__btn:hover .ant-btn-icon,.animated__hover__btn:hover .ant-tag  {
    margin-inline-end: 8px !important;
}

.bordered {
    border: 1px solid #ccc;
}

.resizer {
    display: inline-block;
    background: transparent;
    width: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    cursor: col-resize;
    touch-action: none;
}

.resizer:hover {
    background-color: #8ecae6;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}