// .data-grid-container .data-grid {
// 	width: 100% !important;
// 	background: "green";
// }

#recipe-filter-form {
    .ant-card-body {
        padding: 2px;
    }
}

.show-variation-details-description {
    .ant-descriptions-item {
        padding: 0px !important;
    }
}

.ant-table-cell-row-hover{
    background: white !important;
  }

.prediction_result_filter_sorting_form {
    .ant-form-item {
        margin-bottom: 12px;
    }
}