@import "../SignUp/SignUp.scss";

.block {
	display: block;
	color: red;
}

.none {
	display: none;
}
