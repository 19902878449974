// .project-details-header {
//     .ant-page-header-heading-title {
//         max-width: 90%;
//     }
// }

.project-details-header-container {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-bottom: 0.2rem;
}

.ant-list-item-meta-content > h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.project__dropdown__btn {
  width: 100% !important;
  text-align: left !important;
}

.default__menu__item {
  padding: 0rem !important;
}
.project-details-index {
  display: flex;
  gap: 0 !important;
}
