.analytics__graphs__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
}


.analytics__saved__graphs__container {
    display: flex;
    gap: 0.5rem;
}

.graph__history__drawer__collapse .ant-collapse-header {
    padding: 0.5rem !important;
}

.graphs__history__drawer .ant-drawer-header,
.graphs__history__drawer .ant-drawer-body {
    padding: 0.6rem !important;
}

.graph__history__item {
    padding: 0rem !important
}

#data-analytics-graphs {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.common__divider {
    margin: 0.6rem;
}