.notification-read-button{
  background: #fafafa !important;
}

.notification-table {
  user-select: none;

  .ant-table-tbody {
    .ant-table-cell-row-hover {
      background: #fafafa !important;
      cursor: pointer;
    }

    .ant-table-cell {
      padding: 0.75rem !important;
      border-right: 0px !important;
			height: 3rem !important;
    }
  }
}
