.chart-holder {
    display: flex;
  
    & > div:first-child {
      width: 30%;
      max-width: 300px;
      min-width: 300px;
    }
  
    & > div:last-child {
      width: 100%;
      margin-left: 20px;
    }
  }
  
  .highcharts-axis-labels.highcharts-xaxis-labels {
    text-align: center;
  }
  
  .compare-table {
    td {
      border: 1px solid whitesmoke;
    }
  }