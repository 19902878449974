.delete__specimen__and__standard__modal__content__container{
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .delete__specimen__and__standard__modal__actions__container{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

