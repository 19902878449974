.qc_import_data_select{
  .ant-select-selector{
    border-radius: 2px !important;

    .ant-select-selection-placeholder{
      font-size: 11px;
    }
  }
  .ant-select-selection-item{
    font-size: 11px;
  }
}

.qc-metadata-import-modal {
  .ant-modal-confirm-paragraph {
    max-width: 100%;
    text-align: center;
    .ant-modal-confirm-content {
      font-size: 11px;
    }
  }
  .ant-modal-confirm-btns {
    margin-top: 1.5rem;
  }
}