.visualiseDistributionsForm {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.form__item>.ant-form-item-row {
    display: flex !important;
    justify-content: flex-start;
    flex-direction: column;
}