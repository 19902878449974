.inventory-ingredient-property-detail-container {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .header {
    font-size: 0.875rem;
    font-weight: 600;
  }
  .property-detail-category-container {
    padding: 8px;
    .header {
      font-size: 1rem;
      font-weight: 500;
    }
    .inventory-v2-table {
      .ant-table-cell {
        border-right: none!important;
        border-bottom: none;
      }
      thead {
        margin-bottom: 8px;
        .ant-table-cell {
          border-bottom: 0.5px solid #555;
          &::before {
            background-color: transparent!important;
          }
        }
      }
      .ant-table-tbody {
        padding-top: 8px
      }
    }
  }
}
.inventory-v2-custom-table {

  .animated__hover__btn{
    padding: 0.5px 7px !important;
  }

  .ant-table-expanded-row {
    .ant-table-cell {
      background: #fff!important;
    }
  }

  .ant-table-title{
    padding: 1rem 0rem !important;
  }
}
.upload__file__option{
  cursor: pointer;
  padding: 0.5rem;
}

.upload__file__option:hover{
  background-color:  #F5F5F5;;
}

.upload__file__options__overlay>.ant-popover-content>.ant-popover-inner{
  padding: auto 0rem;
}

.file__upload__duplicate__ingredients__container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0rem;
}


.archive__ingredient__button{
  box-shadow: 0px 2px 0px 0px #00000004;
  background: #262626;
  color: #FFFFFF;
  border-color: #000;
  // padding: 0.5rem;
}