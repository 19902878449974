body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.ingredient-expand-modal {
  padding: 0.5rem;
  border: none !important;
}
.ingredient-expand-modal:hover {
  border: 2px solid;
  border-radius: 10px;
  padding-inline: 0.5rem;
}

.ant-breadcrumb-overlay-link {
  margin-inline: 0px !important;
}

.ant-breadcrumb-overlay-link:hover {
  background-color: transparent !important;
  cursor: pointer;
}

.ant-breadcrumb-separator {
  margin-inline: 4px !important;
}
