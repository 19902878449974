.app__container {
    height: 100vh;
    max-height: 100vh;
    background-color: #fff !important;
    background-Image: radial-gradient(at 50% 100%, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, rgba(0,0,0,0.25) 100%) !important;
    background-blend-mode: screen, overlay ;
}

.app__content__layout__container {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff !important;
    background-Image: radial-gradient(at 50% 100%, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, rgba(0,0,0,0.25) 100%) !important;
    background-blend-mode: screen, overlay ;
}

.app__right__content__container{
    padding: 0.75rem 1.5rem 1.5rem 1.5rem;
}