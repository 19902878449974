.category-container {
  height: calc(100vh - 195px);
  .content-container {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    height: 100%;
    .category-sidebar {
      width: 300px;
      background-color: #fafafa;
      position: sticky;
      top: 0;
      height: 100%;
      border-radius: 6px;
      padding-bottom: 16px;
      padding-bottom: 40px;
      overflow-y: auto;
      .header {
        font-weight: 600;
        padding-left: 12px;
        padding-right: 12px;
      }
      .body {
        .category {
          padding: 8px 16px;
          cursor: pointer;
          font-size: 0.875rem;
          line-height: 22px;
          text-transform: capitalize;
        }
        .selected {
          background-color: #e6f7ff;
          border-right: 2px solid #076dd9;
        }
      }
    }
    .main-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      overflow-y: auto;
      padding-bottom: 60px;
      .category-details-container {
        margin-top: 12px;
      }

      .add__custom__property__parameter__container{
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.repository-table {
  .ant-table-cell {
    padding: 8px 12px !important;
  }
}
