@import '../../styles/variables.scss';

.datasheet {
  max-width: 100%;
  table-layout: fixed;
  

  .invalid {
    background-color: #fff1f0 !important;
    color: #f5222d !important;
  }

  .batchSize {
    color: $color-primary !important;
    background-color: #e6f7ff !important;
  }

  .hide__unit__costing {
    display: none;
  }
}