.table-container {
  .infinite-table {
    border-collapse: collapse;
    width: 100%;
  }

  .infinite-table td,
   .infinite-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .infinite-table tr:hover {
    background-color: #f8f8f8;
  }

  .infinite-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f8f8f8;
    color: rgb(0, 0, 0);
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .table-icons {
    cursor: "pointer";
  }

  .table-data-center {
    text-align: center;
  }
}
