.cursor-row {
  cursor: pointer;
}

.project__cards__container .ant-card-body {
  padding: 0.5rem 1rem !important;
}

.project__cards__container .ant-card-head {
  min-height: 60px;
}

.project-wo {
  width: 25%
}