.formulation-filters {
    .ant-form-item-control-input {
        min-height: 28px;
    }

    .ant-input {
        padding-bottom: 0;
    }

    .ant-btn {
        padding-top: 0;
        padding-bottom: 0;
        height: unset;
        border-radius: 10px;
    }

    // .ant-steps-item-title{
    //     width: 100%;
    // }
}
.work-order-type-selection {
    .ant-select-selection-placeholder {
        color: #000
    }
    .ant-select-arrow {
        color: #000
    }
}