.available__taguchi__designs {
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.taguchiDesignHeader {
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
}


.taguchi__header__text{
    font-family: Roboto;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.taguchi__data__text{
    width: 45px;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}