.qc-metadata-close-modal {
  .ant-modal-confirm-paragraph {
    max-width: 100%;
    text-align: center;
    .ant-modal-confirm-content {
      font-size: 11px;
    }
  }
  .ant-modal-confirm-btns {
    margin-top: 1.5rem;
  }
}

.qc-metadata-save-as-dropdown{
  .ant-dropdown-menu{
    border-radius: 2px;
  }
}