.catwise-category-constraints-tab-table {
    overflow-x: hidden !important;

.cell.read-only {
        background-color: transparent !important;
    }

  .cell {
      color: black !important;
      border: 0px !important;
      border-bottom: 1px solid #d4d4d45c !important;
  }

  tr:first-child {
      .cell {
          background-color: #f3f3f3 !important;
      }
  }
}
.catwise-parameter-constraints-tab-table {
    .cell.read-only {
        background-color: transparent !important;
    }

    .cell {
        color: black !important;
        border: 0px !important;
        border-bottom: 1px solid #d4d4d45c !important;
    }

    tr:first-child {
        .cell {
            background-color: #f3f3f3 !important;
        }
    }
}

.min__max__input{
    width: 100%;
}

.catwise-individual-category-container{
    .data-grid-container{
        overflow-x: hidden !important;
        border-radius: 6px 6px 0px 0px !important;
    }
}