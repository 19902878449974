.field-container {
  min-height: calc(100vh - 195px);
  margin-bottom: 60px;
  .content-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
    .custom-fields-details-container {
      .input-showcase {
        width: 200px;
      }
      .touple-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
.repository-table {
  .ant-table-cell {
    padding: 8px 12px !important;
  }
}
