.lab__location__header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table__header {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 1rem;
}

.table__header__search {
    width: 15rem;
}