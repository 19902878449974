.stepper {
  .in-progress {
    background-color: #e6f4ff;
    color: #1677ff;
  }
  .current {
    background-color: #1677ff;
    color: #fff;
  }
  .pending {
    color: rgba(0, 0, 0, 0.65);
    background-color: rgba(0, 0, 0, 0.06);
  }
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .next-icon {
    font-size: 0.75rem;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.65);
  }
  .done-icon {
    color: #1677ff;
  }
  .stepper-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px
  }
}
