tbody .table-name-column {
  z-index: 1;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  width: 200px !important;
  height: 120px !important;
}

.highlighted {
  font-weight: bold;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.gap-0 {
  gap: 0px !important;
}

.gap-8 {
  gap: 8px !important
}

.gap-10 {
  gap: 10px !important
}