.delete-model {
  width: "437px";
  .ant-modal-header,
  .ant-modal-body {
    padding: "16px";
  }
}

.teams-pending-invitation-table{
  user-select: none;

  .ant-table-thead {
    .ant-table-cell {
      padding: 0.75rem !important;
      border-right: 0px !important;
      border-left: 0px !important;

      font-size: 0.75rem !important;
      height: 2.5rem !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 0.5rem !important;
      border-right: 0px !important;
      font-size: 0.75rem !important;
      height: 2.5rem !important;
    }
  }
}
