#feedback {
    .ant-pagination-options {
        float: right
    }

    .feedback-status {
        margin-left: 2rem;
        border: solid;
        padding: 0.2rem;
        border-radius: 10px;
    }
}