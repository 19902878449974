.qc_criteria_table_select{
  .ant-select-selector{
    border-radius: 2px !important;
  }
}

.qc-criteria-more-options{
  .ant-dropdown-menu{
    border-radius: 2px;
  }
}