.work__order__error {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem;
    border: 1px solid #ececec;
    border-radius: 0.5rem;
    gap: 1rem;
}

.work__order__custom__field{
    display: flex;
    width: "100%";
}