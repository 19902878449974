$black-color: #131313;
$orange-color: #ff8100;
$blue-color: #137cbd;
$blue-dark-color: #004071;
$white-color: #fff;
$error-color: red;
$border-color-1: #d2d5d8;
$border-color-2: #b7b7b7;
$light-bg: #f5f5f5;

$color-primary: #1677ff;
