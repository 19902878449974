@import '../../../../styles/variables.scss';

.property-container {
  height: calc(100vh - 195px);
  .content-container {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    height: 100%;
    .property-sidebar {
      width: 300px;
      background-color: #fafafa;
      position: sticky;
      top: 0;
      height: 100%;
      border-radius: 6px;
      padding: 16px 0;
      padding-bottom: 40px;
      overflow-y: auto;
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        padding-left: 12px;
        padding-right: 12px;
        .properties {
          font-weight: 600;
        }
      }
      .body {
        margin-top: 20px;
        .ant-collapse {
          background-color: #fafafa;
          .ant-collapse-item:last-child {
            .ant-collapse-content {
              background-color: #fafafa;
              .ant-collapse-content-box {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
          .ant-collapse-item {
            .ant-collapse-content {
              .ant-collapse-content-box {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
        .property {
          display: flex;
          justify-content: flex-start;
          gap: 8px;
          cursor: pointer;
          padding: 8px 16px;
        }
        .selected {
          background-color: #e6f7ff;
          border-right: 2px solid #076dd9;
        }
      }
    }
    .property-details {
      flex-grow: 1;
      padding-bottom: 60px;
      overflow-y: auto;
      margin-right: 10px;
      .header {
        font-size: 1.25rem;
        font-weight: 600;
        display: flex;
        justify-items: flex-start;
        gap: 12px;
        .property-edit {
          cursor: pointer;
        }
      }
      .content {
        margin-top: 20px;
        width: 100%;
        .property-parameter-container, .property-unit-container, .property-standard-container, .property-specimen-container {
          margin-top: 20px;
          .collapse-header-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            .collapse-header {
              font-size: 0.75rem;
              font-weight: 600;
            }
            .add-new-cta {
              color: $color-primary;
              cursor: pointer;
              font-size: 0.813rem;
            }
          }
          .ant-collapse {
            .ant-collapse-item {
              .ant-collapse-content {
                .ant-collapse-content-box {
                  padding: 0;
                  .parameter-list, .unit-list, .standard-list, .specimen-list{
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.875rem;
                    padding: 16px;
                    border-bottom: 1px solid #d9d9d9;
                  }
                }
              }
            }
          }
        }
        .methods-container {
          margin-top: 20px;
          .header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            .methods-title {
              font-size: 1rem;
            }
            .methods-add {
              color: $color-primary;
              cursor: pointer;
              font-size: 0.75rem;
            }
          }
          .method-content {
            .methods-content-header {
              font-size: 0.875rem;
              font-weight: 600;
              padding-bottom: 12px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 16px;
            }
          }
        }
      }
    }
  }
}
.repository-table {
  .ant-table-cell {
    padding: 8px 12px!important;
  }
}
