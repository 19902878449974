@import '../../styles/variables.scss';

/* width */

::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white; 
}

/* Handle */
:hover::-webkit-scrollbar-thumb {
	visibility: visible;
}

::-webkit-scrollbar-thumb {
  background: #2f3756a1; 
  border-radius: 3px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;

	visibility: hidden;
}


.sidebar-background {
	background-color: #1A1C24 !important;
}

.sidebar-dark-menu{
		color: $white-color !important;
		padding: 0rem 0.5rem !important;
		outline: none !important;

		.ant-menu-sub{
			color: $white-color !important;
			padding: 0.25rem 0.25rem !important;
			outline: none !important;
			background-color: #1A1C24 !important;
		}

		.ant-menu-item-active{
			background-color: #2f375651 !important;
		}

		.ant-menu-item-selected{
			background-color: #2F3756 !important;
		}

		.ant-menu-inline{
			background: transparent !important;
		}
}

.sidebar-dark-menu::-webkit-scrollbar-thumb{
	background-color: #727787 !important;
}

.sidebar-dark-menu::-webkit-scrollbar-track{
	background: transparent !important;
}

.children-container {
	background-color: #f5f5f5;
	min-height: 100%;
	padding: 25px 20px;
}

.navbar-group {
	width: 100%;
}

.navbar-items {
	display: flex;
	justify-content: flex-end;
	float: none;
	width: 100%;
}

.styled-sider {
	// border-radius: 5px !important;
	background: none !important;
	box-shadow: 0px 0px 3px #c5c5c5 !important;

	.ant-menu {
		background: none;
	}

	.ant-layout-sider-light {
		background: none !important;
	}

	.ant-layout-sider-trigger {
		background: none !important;
	}
}


// .celsure_segment .ant-segmented-item,
// .pcm_segment .ant-segmented-item {
// 	padding: 5px !important;
// }

.celsure_segment .ant-segmented-item-selected {
	background: #e6f4ff !important;
	border: 1px solid #bcdfff
}

.pcm_segment .ant-segmented-item-selected {
	background: #fefbe6 !important;
	border: 1px solid #fff0bb
}

.project-selection {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.ant-select-selector {
		height: 60px !important;
		background-color: $color-primary !important;
		color: white;
		font-weight: bold;
		padding-left: 28px !important;
			padding-right: 28px !important;
		padding-top: 24px !important;
		padding-bottom: 12px !important;
		border: none !important;

		.ant-select-selection-item {
			display: flex;
			padding-top: 8px !important;
			font-size: 0.875rem !important;
			line-height: 30px !important;

			.ant-typography{
				color: white !important;
			}
		}

		&::before {
			content: attr(data-label);
			position: absolute;
			top: 12px;
			font-size: 0.75rem;
			font-weight: bold;
			color: white !important;
		}
	}

	.ant-select-arrow {
		color: white;
		margin-right: 12px !important;
	}
}

.project-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	width: 8em;
}

.projects-title-menu {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block !important;
	width: 12em;
}
.project-selection>div>span.ant-select-selection-item {
	color: white !important;
}

.project-selection>div>span.ant-select-selection-item {
	color: white !important;
}

.ant-menu-item{
	outline: none !important;
	// margin-inline: 0 !important;
	// width: 100% !important;
	// border-radius: 0px !important;
}

.ant-menu-submenu-title{
	outline: none !important;
}

// .ant-menu-item-selected{
	// border-right: 3px solid;
// }
.project-selection-filter {
	color: $color-primary;
	margin-right: 12px;
	font-size: 0.75rem;
	padding: 2px 4px;
}
.lab-location-filter-list {
	max-height: 250px;
	width: 300px;
	overflow-y: auto;

	.list-item {
		padding: 8px 4px;
	}
}
