.verify-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  .box {
    width: 80%;
    border: 1px solid lightgrey;
    text-align: center;
    padding: 40px;
    border-radius: 4px;
    .action {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      background-color: cornflowerblue;
      color: white;
      cursor: pointer;
    }
  }
}
