
.directory-card{
  border: 1px solid #c1c1c1;
  transition: all 0.3s;
  user-select: none;

  .copy-asset-file-link-icon:hover{
    background-color: #f5f5f5;
  }
}
.directory-card:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    border-color: transparent !important;
}