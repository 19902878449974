@import '../../../../styles/variables.scss';

.parameter-container {
  min-height: calc(100vh - 195px);
  margin-bottom: 60px;
  .content-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
    .parameter-details-container {
      display: block;
      width: 100%;
      .parameter-detail {
        .parameter-details-header {
          display: flex;
          justify-content: flex-start;
          gap: 12px;
          .header-title {
            font-size: 0.875rem;
            line-height: 22px;
            font-weight: 500;
          }
          .header-link {
            cursor: pointer;
            color: $color-primary;
          }
        }
        .parameter-details-content {
            padding-top: 12px;
        }
      }
    }
  }
}
.repository-table {
    .ant-table-cell {
      padding: 8px 12px!important;
    }
  }
