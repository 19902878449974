.sem-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
  padding-top: 20px;
  .card {
    width: 180px;
    .thumbnail {
      width: 180px !important;
      height: 180px !important;
      object-fit: fill;
    }
  }
  .thumb-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    max-width: 182px;
    .img-container {
      position: relative;
      width: 180px;
      height: 180px;
      img {
        object-fit: contain;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .selection {
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }
    .info {
      padding: 8px;
      padding-right: 0;
      width: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      color: #00000073;
      font-size: 0.75rem;
      gap: 6px;

      .file-name {
        color: #00000073;
        font-size: 0.75rem;
        gap: 6px;
        margin-left: 0;
        .ant-typography {
          margin: 0;
          padding: 0;
        }
      }
    }
    .dropdown {
      padding: 8px;
    }
  }

  .card-img-placeholder {
    width: 180px;
    height: 188px;
    display: grid !important;
    place-items: center;
  }
}

.batch-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 12px;
  .batch {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 24px 8px 12px;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    font-weight: 500;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    .dropdown {
      padding-left: 12px;
    }
  }
}

.sem-drawer {
  .container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .canvas-container {
    .canvas-wrapper {
      position: relative;
      background-color: #262626;
    }
  }

  .main-canvas {
    width: 0;
    height: 0;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .calibrate-input-cards {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end;
    .card {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: #262626;
      border-radius: 6px;
      padding: 20px;
      width: 100%;
      .label {
        display: flex;
        align-items: center;
        .anticon {
          margin-left: 5px;
        }
      }
      input {
        background-color: #434343;
        border-radius: 6px;
        border: none;
        width: 80px;
        height: 40px;
        color: #fff;
        padding: 10px;
      }
    }
  }

  .bottom-fixed {
    position: sticky;
    top: calc(100% - 70px);
  }

  .tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 0.75rem;
    padding: 8px;
    pointer-events: none;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    width: 100%;
    padding: 10px 20px;
    height: 70px;

    > div {
      flex: 1;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }

    .ant-switch:not(.ant-switch-checked) {
      background-color: #bfbfbf;
    }
  }

  .table-wrapper {
    background-color: #fff;
    padding: 30px;
    color: #000;
    h3 {
      margin-bottom: 10px;
    }
  }
}

.sem-analysis-image-upload,
.paint-film-image-upload, .fiber-image-upload {
  width: 100%;
  min-height: 200px;
  .icon {
    font-size: 2rem;
  }
  h4 {
    margin-bottom: 5px;
  }
  p {
    font-size: 0.75rem;
  }
}
.paint-film-image-upload, .fiber-image-upload {
  min-height: 120px;
}
.quadrant-table-input {
  border: none !important;
  width: 100px;
  .ant-select-selector {
    border: none !important;
  }
}
.image-preview-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  .image-preview {
    position: relative;
    width: 150px;
    height: 150px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border-radius: 8px;
      object-fit: contain;
      max-width: 134px;
      max-height: 134px;
    }
    .close {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -10px;
      right: -10px;
      background-color: #f1f1f1;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      font-size: 0.75rem;
      cursor: pointer;
    }
  }
}
.quadrant-table-input::-webkit-outer-spin-button,
.quadrant-table-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.paint-film-analysis-container, .fiber-analysis-container {
  margin-top: 20px;

  .content-container {
    .content-header {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      margin: 20px 0;
      .back {
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
      }
      .batch-name {
        font-size: 1rem;
        font-weight: 500;
      }
      .link {
        color: #1677ff;
        cursor: pointer;
      }
    }
  }
}
.paint-film-analysis-drawer, .fiber-analysis-drawer {
  .container {
    display: flex;
    gap: 12px;
    min-width: 100%;
    min-height: 100%;
    background-color: #1f1f1f;
    color: white;
    .sidebar {
      width: 18%;
      position: relative;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 12px;
        gap: 20px;

        .option, .multi-option {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: #262626;
          border-radius: 6px;
          padding: 16px;
          width: 100%;
          .label {
            display: flex;
            align-items: center;
            .anticon {
              margin-left: 5px;
            }
          }
          .error-div {
            font-size: 0.688rem;
            padding-top: 4px;
            color: #f24343;
          }
          input {
            background-color: #434343;
            border-radius: 6px;
            border: none;
            width: 80px;
            height: 40px;
            color: #fff;
            padding: 10px;
          }
        }
        .multi-option {
          flex-direction: column;
          align-items: stretch;
          gap: 12px;
        }
        .setting-collapse {
          border: none;
          background-color: #262626;
          .ant-collapse-item {
            border: none;
            .ant-collapse-header {
              color: #fff;
            }
            .ant-collapse-content {
              background-color: #262626;
              color: #fff;
              .ant-collapse-content-box {
                padding: 4px;
              }
            }
          }
        }
      }
      .option {
        display: flex;
        align-items: center;
        background-color: #262626;
        border-radius: 6px;
        padding: 16px;
        width: 100%;
        .label {
          display: flex;
          align-items: center;
          word-wrap: break-word;
          margin-right: 8px;
          .anticon {
            margin-left: 5px;
          }
        }
        .error-div {
          font-size: 0.688rem;
          padding-top: 4px;
          color: #f24343;
        }
      }
      .bottom-fixed {
        position: sticky;
        top: calc(100% - 70px);
      }
      .sem-slider {
        .ant-slider-mark {
          .ant-slider-mark-text {
            color: #fff;
            font-size: 10px;
          }
        }
        .ant-slider-rail {
          background-color: #fff;
        }
      }
      .sem-slider-no-track {
        .ant-slider-track {
          background-color: #fff;
        }
        .ant-slider-step {
          .ant-slider-dot-active {
            border-color: #f0f0f0;
          }
        }
      }
      .sem-slider-inverse-track {
        .ant-slider-rail {
          background-color: #91caff;
        }
        .ant-slider-track {
          background-color: #fff;
        }
      }
    }
    .image-mask-container {
      flex-grow: 1;
      min-height: 100%;
      margin: 20px;
      background-color: #262626;
      overflow-x: auto;
    }
  }
  .info-container {
    padding: 20px;
  }
}
.contrastive-analysis-modal {
  .selected-border {
    border: 1px solid #1677ff;
  }
}
