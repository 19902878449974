.widget-container {
  .widget-open-button {
    position: fixed;
    bottom: 300px;
    right: 0px;
    width: auto;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    cursor: pointer;
    color: #0958d9;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    transform: rotate(-90deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  .ant-badge {
    color: #0958d9;
  }
  .flash {
    animation: blinkingBackground 1s;
  }
  @keyframes blinkingBackground {
    0% {
      background-color: #ffffff;
      color: #0958d9;
    }
    100% {
      background-color: #ff4d4f;
      color: #fff;
    }
  }
}
.widget-drawer {
  .ant-drawer-content-wrapper {
    bottom: 0;
    top: auto;
    .ant-drawer-body {
      position: relative;
      padding: 0;
      .drawer-header {
        display: flex;
        padding: 5px 10px;
        justify-content: space-between;
        position: sticky;
        top: 0px;
        left: 0px;
        right: 0px;
        background: #fff;
        border-bottom: #dedede;
        padding: 16px;
        z-index: 99;
        font-size: 1rem;
        font-weight: 600;

        .drawer-close {
          cursor: pointer;
          font-size: 1.125rem;
        }
      }
      .drawer-body {
        padding: 0 16px;
        margin-bottom: 50px;
      }
    }
  }
}
.stepper-block {
  padding-left: 10px;
}
