@import '../../../../styles/variables.scss';

.no-data {
  .content {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cta-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
  }
}
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .total-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .properties {
      font-weight: 600;
    }
  }
}
.edit-method-drawer {
  .drawer-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .drawer-subtitle {
    font-size: 0.75rem;
    font-weight: 500;
    color: #8c8c8c;
  }
  .method-drawer-body {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    .method-drawer-empty {
      flex-grow: 1;
    }
  }
}
.property-drawer {
  .ant-drawer-content-wrapper {
    box-shadow: none;
    .ant-drawer-content {
      background-color: #181818;
      color: #fff;
      .body {
        padding-inline: 256px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .selected-items {
          display: flex;
          justify-content: flex-start;
          gap: 12px;
          align-items: center;
        }
        .cta {
          display: flex;
          justify-content: flex-start;
          gap: 16px;
          align-items: center;
          .deactivate,
          .delete {
            background-color: #2e2e2e;
            padding: 8px;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        .dismiss {
          .dismiss-button {
            background-color: transparent;
            color: #fff;
            border: 1px solid #fff;
          }
        }
      }
    }
  }
}
.repository-cascader {
  .add-button {
    color: $color-primary;
    cursor: pointer;
    padding: 8px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.repository-table {
  .ant-table-cell {
    padding: 8px 12px !important;
    border-right: none !important;
    max-width: 160px;
  }
  .ant-form-item{
    margin: 0 !important;
  }
}
.custom-table-input {
  // border: none !important;
  width: 100%;
  // .ant-select-selector {
  //   border: none !important;
  // }
}
.custom-table-input::-webkit-outer-spin-button,
.custom-table-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.subcategory-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #e6f4ff;
  font-weight: 600;
  .subcategory-options-delete {
    cursor: pointer;
  }
}
.method-parameter-container {
  .method-parameter-header {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    .method-parameter-title {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .method-parameter-content {
    margin-top: 12px;
    .range-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
    .parameter-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      .delete-icon {
        cursor: pointer;
      }
    }
  }
}
.parameter-dropdown {
  background-color: #fff;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  .dropdown-header {
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dropdown-close {
      cursor: pointer;
    }
  }
  .search-container {
    padding: 8px 16px;
  }
  .parameter-list-container {
    height: 15rem;
    overflow-y: auto;
    padding: 8px 0;
    .parameter-list-item {
      padding: 4px 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 22px;
    }
    .parameter-disabled {
      color: #bfbfbf!important;
    }
    .parameter-selected {
      background-color: #e6f7ff;
      color: $color-primary;
    }
  }
  .parameter-list-footer {
    background-color: #fafafa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-top: 8px;
    .parameter-list-cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
  }
}


.property__value__form{
  .ant-form-item{
    margin: 0 !important;
    padding: 0.25rem 0 !important;
  }
}
.repository-custom-modal {
  .custom-field-switch-container {
    .ant-row {
      flex-direction: row;
      align-items: center;
      .ant-col {
        padding-bottom: 0;
      }
    }
  }
}
