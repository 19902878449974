.dashboard_underline_text:hover {
  text-decoration: underline !important;
}

.dashboard-notification-filter {
  .ant-segmented-item-selected {
    background-color: black !important;
    color: white !important;
    transition: none !important;
  }

  .ant-segmented-item {
    border: 0.25px solid black !important;
    transition: none !important;
  }

  .ant-segmented-group {
    gap: 8px !important;
  }
}

.dashboard-notification-item:hover {
  cursor: pointer;
  background-color: #fafafa !important;
  user-select: none;
}

.dashboard-recent-edits-table {
  user-select: none;

  // Done beacuse pagination was also scrolling with table
  .ant-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ant-table-title {
    padding: 0 !important;
    padding-bottom: 10px !important;
  }
  .ant-table-thead {
    
    .ant-table-cell {
      padding: 12px !important;
      border-right: 0px !important;
      border-left: 0px !important;

      font-size: 0.75rem !important;
      background-color: white !important;
      color: #838383 !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell-row-hover {
      background: #fafafa !important;
      cursor: pointer;
    }

    .ant-table-cell {
      padding: 12px !important;
      border-right: 0px !important;
      font-size: 0.75rem !important;
    }
  }
}

.dashboard-ingredients-filter, .dashboard-activity-predictions-table-filter {
  padding: 0px !important;
  .ant-segmented-item-selected {
    background-color: black !important;
    color: white !important;
    transition: none !important;
  }

  .ant-segmented-item {
    transition: none !important;
  }

  .ant-segmented-group {
    padding: 4px !important;
    border: 1px solid #d8d8d8 !important;
    border-radius: 8px !important;
    background-color: white !important;
    box-shadow: 0px 0px 0px !important;
    gap: 4px !important;
  }
}

.dashboard-members-table {
  user-select: none;

  // Done beacuse pagination was also scrolling with table
  .ant-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ant-table-title {
    padding: 0 !important;
    padding-bottom: 10px !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 12px !important;
      border-right: 0px !important;
      border-left: 0px !important;

      font-size: 0.875rem !important;
      background-color: white !important;
      color: #838383 !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 18px !important;
      border-right: 0px !important;
    }
  }
}

.dashboard-project-trials-table {
  user-select: none;

  // Done beacuse pagination was also scrolling with table
  .ant-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 12px !important;
      border-right: 0px !important;
      border-left: 0px !important;

      font-size: 0.875rem !important;
      background-color: white !important;
      color: #838383 !important;
    }
  }

  .ant-table-tbody {
    .ant-table-cell-row-hover {
      background: #fafafa !important;
      cursor: pointer;
    }

    .ant-table-cell {
      padding: 18px 12px !important;
      border-right: 0px !important;
    }
  }
}

.highcharts-point {
  opacity: 1 !important;
}

.highcharts-point:hover {
  cursor: pointer;
  opacity: 1 !important;
}

.dashboard-activity-card {
  transition: all 0.3s !important;
}

.dashboard-activity-card-view-all {
  transition: all 0.3s !important;
}

.dashboard-activity-card:hover {
  cursor: pointer;
  scale: 1.03;
  background-color: white;
  outline-width: 1.5px;

  .dashboard-activity-card-view-all {
    text-decoration: underline !important;
  }
}

.dashboard-recent-edits-work-order-name:hover{
  text-decoration: underline !important;
}

.dashboard-pie-chart-workorder-drawer-table,
.dashboard-activity-datasets-drawer-table,
.dashboard-activity-projects-drawer-table,
.dashboard-activity-workorders-drawer-table {
  user-select: none;

  .ant-table-thead {
    .ant-table-cell {
      padding: 8px !important;
      border-right: 0px !important;
      border-left: 0px !important;

      font-size: 0.75rem !important;
      background-color: white !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 8px !important;
      border-right: 0px !important;
      font-size: 0.75rem !important;
      height: 43px !important;
    }
  }

  .ant-table-filter-trigger {
    outline: none !important;
    margin-right: 2px !important;
  }
}

.ant-table-filter-dropdown {
  user-select: none !important;

  .ant-dropdown-menu-item {
    outline: none !important;

    .ant-dropdown-menu-title-content {
      font-size: 0.75rem !important;
      font-weight: 500 !important;
      display: flex !important;
      align-items: center !important;
    }
  }

  .ant-table-filter-dropdown-btns {
    justify-content: flex-end !important;

    button {
      outline: none !important;
      font-size: 0.75rem !important;
      font-weight: 500 !important;
    }
  }

  .ant-table-filter-dropdown-search-input {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
  }
}

.dashboard-notification-filter{
  .ant-select-selection-item{
    display: flex !important;
  }
}

.ant-avatar-group-popover {
  .ant-popover-inner-content {
    display: flex !important;
    max-width: 150px !important;
    flex-wrap: wrap !important;
  }
}