.unit-container {
    min-height: calc(100vh - 195px);
    margin-bottom: 60px;
    .content-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 16px;
      .unit-details-container {
          margin: 12px 0 40px 0;
      }
    }
  }
  .repository-table {
    .ant-table-cell {
      padding: 8px 12px!important;
    }
  }
  