.heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 0;
  }
}

.full-width {
  width: 100%;
}

.form-container {
  width: 60%;
  margin-left: 10%;
}

.formulations__collapse .ant-collapse-header {
  padding: 0.5rem 1rem !important;
  display: flex !important;
    align-items: center !important;
}

.bp3-form-group.bp3-inline.form-group-inline {
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;

  .bp3-label {
    width: 100%;
  }

  .bp3-form-content {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .bp3-form-group.bp3-inline.form-group-inline {
    justify-content: space-between;
    flex-direction: row;

    .bp3-label {
      width: 40%;
    }

    .bp3-form-content {
      width: 60%;
    }
  }
}
