.category-tabs {
  padding: 1rem 0rem;
  
    .ant-tabs-content-holder {
      padding-top: 1rem;
    }

  .ant-tabs-nav::before{
    border: 0px;
  }
  .ant-tabs-nav-list {
    background-color: #ecf0f3;
    border-radius: 6px;
    line-height: 0.75;
    padding: 4px;
  }

  .ant-tabs-tab {
    margin: 0px;
    border-radius: 6px !important;
    background-color: #ecf0f3 !important;
    border: none !important;
    padding: 6px 16px!important;
  }

  .ant-tabs-tab-btn {
    color: black !important;
    border: none !important;
    outline: none !important;
  }

  .ant-tabs-tab-active {
    border: 0px !important;
    background-color: #fff !important;

    .ant-tabs-tab-btn:hover {
      color: black !important;
    }
  }
}