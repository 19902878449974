.first__time__update__password__container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .first__time__update__password__form{
        margin: 1rem;
        padding: 3rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-direction: column;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 1.5rem;
        width: 30rem;
        transition: width 0.5s ease;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
                 radial-gradient(87.68% 87.68% at 50% 0%, rgba(255, 177, 85, 0.07) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid linear-gradient(209.66deg, rgba(255, 255, 255, 0.22) 3.43%, rgba(255, 147, 70, 0.1) 96.92%)
    }

    .first__time__update__password__form__title__container{
        display: flex;
        flex-direction: column;

        .first__time__update__password__form__title{
            font-family: Work Sans;
            font-size: 1.855rem;
            font-weight: 400;
            line-height: 2.176rem;
            letter-spacing: 0.015em;
            text-align: left;
            padding: 0 !important;
            margin: 0 !important;
        }
        .first__time__update__password__form__title__desc{
            font-family: Work Sans;
            font-size: 0.595rem;
            font-weight: 400;
            line-height: 1.962rem;
            letter-spacing: -0.005em;
            text-align: left;
        }
    }

    .first__time__update__password__form__link__container{
        text-decoration: none;
        color: #000;
    }

    .first__time__update__password__company__info__container{
        display: flex;
        gap: 0.25rem
    }

    .update__password__container{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%
    }

    .update__password__form__container{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .info__icon{
        margin-right: 0.156rem
    }

   .required__star__icon {
        color: #ff4d4f;
        font-size: 0.875rem
    }

    .update__password__header{
        font-family: Work Sans;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.759rem;
        text-align: center;
    }

    .update__password__btn{
        width: 100%;
    }

    .first__time__update__password__footer,.first__time__update__password__footer__links{
        display: flex;
        gap: 0.25rem;
        align-items: center;
    }

    .first__time__update__password__footer__link{
        font-family: Work Sans;
        font-size: 0.625rem;
        font-weight: 500;
        line-height: 0.847rem;
        text-align: left;
        color: #5F85EB;
    }

    .first__time__update__password__footer__copyright{
        font-family: Work Sans;
        font-size: 0.624rem;
        font-weight: 500;
        line-height: 0.846rem;
        text-align: left;
    }

    .first__time__update__password__footer__container{
        position: fixed;
        bottom: 0;
        z-index: 50;
        padding: 0.5rem;
    }

}

@media (max-width: 600px) {
    .first__time__update__password__form {
      width: 50rem !important;
      padding: 1rem !important;
    }
    .first__time__update__password__footer__container{
        margin: 0.5rem;
        padding: 0.25rem;
    }
    .first__time__update__password__footer{
        flex-wrap: wrap;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
    }
  }