.billing__tab__container {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    overflow-x: clip;
    padding: 0.25rem
}

.billing__tab__container__loading {
    filter: blur(3px) !important;
    -webkit-filter: blur(3px);
    transition: filter 500ms ease;
}

.payment__method__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid #ececec;
    border-radius: 0.5rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.credit__card__icon {
    font-size: 1.4rem;
}

.billing__detail__name,
.billing__detail__email {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.payment__method__info__container {
    display: flex;
    gap: 1rem;
}

.common__billing__title {
    margin: 0rem !important;
}

.billing__currentplan__container,
.current__plan__info,
.billing__detail__container,
.billing__detail__info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.current__plan__info,
.billing__detail__info {
    gap: 0.3rem;
}

.common__divider {
    margin: 0.6rem;
}

.billing__history__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid #eaeaea
}

.billing__error__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem;
    border: 1px solid #ececec;
    border-radius: 0.5rem;
    gap: 1rem;
}

.payment__method__container__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}