.categorical-output-constraints-table {
  .cell.read-only {
    background-color: transparent !important;
  }

  .cell {
    color: black !important;
    border: 0px !important;
    border-bottom: 1px solid #d4d4d4 !important;
  }

  tr:first-child {
    .cell {
      background-color: #f3f3f3 !important;
    }
  }
}