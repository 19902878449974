.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: 65vh;
    justify-content: space-around;
}

.upload-title {
    color: #262626;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
}

.upload-message {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    color: #000000;
    text-align: center
}

.file-size {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #888888;
    margin-left: 0.5rem;
}

.file-name {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 0.5rem;
    width: "100%";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-name:hover {
    text-decoration: underline;
}

.chat-file-header {
    display: flex;
    align-items: center;
    padding: 16px;
}

.title {
    width: 100%;
    padding: 16px;
    background: #FAFAFA;
    border-radius: 12px;
    display: flex;
}

.file-upload-status {
    color: #8C8C8C;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.18000000715255737px;
    text-align: left;
    padding-left: 16px;
}

.chat-input-field {
    position: absolute;
    bottom: -30px;
    width: 100%;
}

.ask-polygpt {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BFBFBF;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
}

.ant-list-item-action {
    margin-block-start: 0px;
}

.gpt-list.css-dev-only-do-not-override-fpg3f5>div.ant-spin-nested-loading.css-dev-only-do-not-override-fpg3f5>div>ul>li>div.ant-list-item-main>ul {
    margin-top: 0 !important;
}

.gpt-list .ant-list-items {
    height: 70vh;
    overflow-y: auto;
}

.gpt-list .ant-list-item-action {
    margin-block-start: 0px!important;
}

.ask-questions-input {
    line-height: 25px !important;
}

.error-conversation {
    padding: 12px;
    background: #FFF2F2;
    color: #E02424;
    width: 45vw;
    border-radius: 12px;
}