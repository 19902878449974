.attachments__list__containers{
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    max-height: 40rem;
    overflow-y: auto;
    auto-snap-align: start;
    auto-snap-type: y mandatory;
    auto-behavior: smooth;
    padding: 0.5rem;
}

.attachments__list__containers::-webkit-scrollbar {
    width: 0.10rem;
    height: 0.5rem;

}

.attachments__list__containers::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 0.10rem;

}

.attachments__list__containers::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.attachment__action__container{
    display: flex;
    gap: 0.5rem;
}

.attachment__title__container{
    padding: 0.2rem 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.2rem;
    border-bottom: 1px solid #ccc
}

.attachment__title__container:last-child{
    border-bottom: none;
}