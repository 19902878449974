.kbar {
  z-index: 99;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(4px);
  .content {
    box-shadow: 0px 0px 10px 0px rgba(207, 215, 231, 0.568);
    border: 1px solid #eee;
    border-radius: 12px;
    overflow: hidden;
    .search-bar {
      padding: 18px 36px;
      border: none;
      outline: none;
      width: min(600px, 80vw);
      border-radius: 12px 12px 0px 0px;
      border-bottom: 1px solid #eee;
    }
    .search-item {
      cursor: pointer;
      padding: 12px 36px;
    }
  }
}
.kbar {
  z-index: 99;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(4px);
  .content {
    box-shadow: 0px 0px 10px 0px rgba(207, 215, 231, 0.568);
    border: 1px solid #eee;
    border-radius: 12px;
    overflow: hidden;
    .search-bar {
      padding: 18px 36px;
      border: none;
      outline: none;
      width: min(600px, 80vw);
      border-radius: 12px 12px 0px 0px;
      border-bottom: 1px solid #eee;
    }
    .search-item {
      cursor: pointer;
      padding: 12px 36px;
    }
  }
}
.headway-badge {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 8px;
}
