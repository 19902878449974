.datahseet-cell-red {
	color: #ff0000;
	font-weight: 700;
}

.ingredients-table-container{
	.data-grid-container{
		max-height: 500px;
	}
}

.processing-table-container{
	.data-grid-container{
		max-height: 500px;
	}
}

.forward-model-datasheet .value-viewer {
	word-break: break-all !important;
}

.forward-model-datasheet .cell {
	white-space: break-spaces !important;
}

.use-custom-form-item {
	margin-bottom: 0 !important;
}

.forward-model-datasheet {
	// width: 100% !important;
	// overflow: hidden;

	.cell.read-only {
    background-color: white !important;
  }

  .cell {
    color: black !important;
    border: 0px !important;
    border-bottom: 1px solid #d4d4d4 !important;
  }

  tr:first-child {
		position: sticky;
		top: 0;
		z-index: 2;
    .cell {
      background-color: #f3f3f3 !important;
    }
  }
}

.forward-model-datasheet-ingredients {
	tr:last-child {
		position: sticky;
		bottom: 0;
		z-index: 2;
    .cell {
      background-color: #f3f3f3 !important;
    }
  }
}

.no-padding {
	padding: 0 !important;
}

.zeon-prediction-result-custom-table .properties-cell {
	border-bottom: 1px solid #eee;
}

.zeon-prediction-result-custom-table .characterization-name-cell {
	border-right: 1px solid #eee;
}

.zeon-prediction-result-custom-table .parameters-cell {
	border-bottom: 1px solid #eee;
}

.zeon-prediction-result-custom-table .parameters-cell-row:last-child .parameters-cell,
.zeon-prediction-result-custom-table .properties-cell-row:last-child .properties-cell,
.zeon-prediction-result-characterization-table>tbody>tr>td>table:last-child .trial-cell {
	border-bottom: none;
}

.zeon-prediction-result-table table .ant-table-tbody>tr {
	padding: 0 !important;
}

.zeon-prediction-result-characterization-table {
	width: 400px;
	// background: red;
	display: block;
	overflow: auto;
}

.zeon-prediction-result-characterization-table>tbody,
.zeon-prediction-result-characterization-table>tbody>tr {
	width: 100%;
	display: inline-block;
}

.zeon-prediction-result-characterization-table>tbody>tr {
	display: flex;
}

.zeon-prediction-result-characterization-table>tbody>tr>td {
	display: inline-block;
	min-width: 197.5px;
	width: 100%;
	overflow: hidden;
}

.zeon-prediction-result-characterization-table .trial-cell {
	border-bottom: 1px solid #eee;
	border-right: 1px solid #eee;
}

.zeon-prediction-result-characterization-table>tbody>tr>td:last-child>table .trial-cell {
	border-right: none;
}

.zeon-prediction-result-characterization-table .trial-cell,
.zeon-table-cell,
.zeon-prediction-result-table .ant-table-thead>tr>th {
	height: 50px !important;
	padding: 0 10px !important;
	line-height: 50px;
	white-space: nowrap;
}


.dropdown-remove-cell {
	padding: 0px 8px !important;
	width: 400px !important;
	text-align: inherit !important;
}

.dropdown-content-cell {
	text-align: left !important;
}