.smile-analytics-container {
  margin-bottom: 50px;
  .form-container {
    border-radius: 6px;
    background: #fafafa;
    padding: 16px;
    width: 100%;
    margin: 0;
    .mode-container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  .file-list-form {
    .file-label {
      color: #595959;
    }
  }
  .anchor-container {
    background: #fafafa;
    border-radius: 6px;
    padding: 16px 12px;
    position: sticky;
    top: 0;

    .heading {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
  .overview-container {
    .heading {
      font-size: 1rem;
      font-weight: 700;
    }
    .download {
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
      padding: 6px 8px;
      cursor: pointer;
      a {
        color: #000;
      }
    }
    .table-container {
      width: 100%;
      .header {
        height: 64px;
        padding: 8px;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
      }
      .content {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        .img-thumb {
          width: 40px;
          height: auto;
        }
      }
    }
  }
  .numerical-container {
    margin-top: 40px;
    .heading {
      font-size: 1rem;
      font-weight: 500;
    }
    .section-title {
      margin-top: 24px;
      margin-bottom: 16px;
      font-size: 0.875rem;
      font-weight: 500;
    }
    .table-container {
      width: 100%;
      .header {
        height: 64px;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
      }
      .content {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 8px;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
  .heatmap-container {
    margin-top: 40px;
    .heading {
      font-size: 1rem;
      font-weight: 500;
    }
    .notes {
      margin-top: 8px;
      font-size: 0.813rem;
      line-height: 20px;
      ul {
        margin-left: 20px;
      }
    }
    .text-grey {
      color: #595959;
    }
    .chart-container {
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      padding: 24px;
      margin-top: 24px;
      .heatmap-chart {
        margin-top: 40px;
      }
    }
    .loading-heatmap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 10px;
      color: #1677ff;
      font-size: 0.75rem;
      gap: 10px;
    }
    .analysis-container {
      margin-top: 40px;
      .empty-analysis {
        border-radius: 6px;
        padding: 14px;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #d46b08;
        background-color: #fff7e6;
        font-size: 0.75rem;
      }
      .heading {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
      .images-scroller {
        position: relative;
        max-width: 100%;
        .left-paddle,
        .right-paddle {
          position: absolute;
          width: 20px;
          cursor: pointer;
          top: 45%;
          background-color: #fff;
          border-radius: 4px;
          padding: 4px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        }
        .left-paddle {
          left: -10px;
        }
        .right-paddle {
          right: -10px;
        }
        .images-container {
          display: flex;
          flex-direction: row;
          gap: 16px;
          white-space: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;
          .img-container {
            border: 1px solid #ccc;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 200px;
            flex-shrink: 0;
            .image-title {
              padding: 8px;
              font-size: 0.875rem;
              font-weight: 500;
              text-align: center;
              border-top: 1px solid #ccc;
            }
            img {
              width: auto;
              max-width: 100%;
              height: 200px;
              border-radius: 4px;
              object-fit: contain;
            }
          }
        }
        .images-container::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .custom-tooltip {
      position: relative;
      display: inline-block;

      .tooltiptext {
        visibility: hidden;
        background-color: #6c6c6f;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 8px;
        position: absolute;
        z-index: 99;
        top: -10%;
        left: 90%;
        margin-top: -10px;
      }
      .rotate-tooltip-90 {
        // transform: rotate(90deg);
        top: -150%;
        bottom: 150%;
        left: 0;
        margin-left: -20px;
      }
    }
    .custom-tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }
  .smiles-graph-container {
    margin-top: 40px;
    .heading {
      font-size: 1rem;
      font-weight: 700;
    }
    .graph-container {
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      padding: 24px;
      margin-top: 16px;
      .subheading {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
  .back-header {
    margin-bottom: 20px;
    font-weight: 500;
    gap: 12px;
    font-size: 1rem;
  }
}
.molecular-structure {
  max-width: 166px;
  --antd-arrow-background-color: #6c6c6f;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: #6c6c6f;
      .img-small {
        width: 150px;
        height: auto;
      }
    }
  }
}
