.configuration__table{
  .ant-table-title{
    padding: 0 !important;
    padding-bottom: 10px !important;
  }
  .ant-table-thead{
    .ant-table-cell{
        padding: 10px !important;
        border-right: 0px !important;
        text-align: left !important
      }
  }
  .ant-table-tbody{
    .ant-table-cell{
        padding: 0px 10px !important;
        border-right: 0px !important;
      }
  }
}