.qc-files-table-filter-form{
  .ant-form-item{
    margin-bottom: 0 !important;

    .ant-form-item-label{
      label{
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  
}