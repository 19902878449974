.page__container div.filter_form_gap {
  gap: 16px !important
}

.form-filter-tag-renderer{
  margin-right: 3;
  background-color:#0000000f !important;
  color: #000000e0 !important; 
  span{
    color: #000000e0 !important;
  }
}