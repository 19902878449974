.teams-projects-edit-members{
  user-select: none;

  .ant-table-title{
    padding: 0.5rem 0rem !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 0.75rem !important;
      border-right: 0px !important;
      border-left: 0px !important;

      font-size: 0.75rem !important;
      height: 2.5rem !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 0.75rem 1rem !important;
      border-right: 0px !important;
      font-size: 0.75rem !important;
      height: 2.5rem !important;
    }
  }
}