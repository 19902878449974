@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

* {
    margin: 0px;
    padding: 0px;
    font-family: "Inter", sans-serif;
}

#root {
    margin: 0px;
    padding: 0px;
}

.transition-fade-enter {
    opacity: 0;
}

.transition-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.transition-fade-exit {
    opacity: 1;
}

.transition-fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.svg-icon svg {
    position: relative;
    height: 1.5em;
    width: 1.5em;
}

.svg-text svg {
    stroke: #424242;
}

.svg-180 svg {
    transform: rotate(180deg);
}

.form-input {
    padding: 0.375rem;
    background-color: #eeeeee;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    color: #424242;
}

.form-input:focus {
    outline: none;
    box-shadow: 0 0 1px 2px #8ecae6;
}

.is-fullwidth {
    width: 100%;
}

.bg-white {
    background-color: white;
}

.data-input {
    white-space: pre-wrap;
    border: none;
    padding: 0.5rem;
    color: #424242;
    font-size: 1rem;
    border-radius: 4px;
    resize: none;
    background-color: white;
    box-sizing: border-box;
    flex: 1 1 auto;
}

.data-input:focus {
    outline: none;
}

.shadow-5 {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12),
        0 4px 6px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12),
        0 16px 32px rgba(0, 0, 0, 0.12);
}

.svg-icon-sm svg {
    position: relative;
    height: 1.25em;
    width: 1.25em;
}

.svg-gray svg {
    stroke: #9e9e9e;
}

.option-input {
    width: 100%;
    font-size: 1rem;
    border: none;
    background-color: transparent;
}

.option-input:focus {
    outline: none;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;
    overflow: hidden;
}

.sort-button {
    padding: 0.25rem 0.75rem;
    width: 100%;
    background-color: transparent;
    border: 0;
    font-size: 0.875rem;
    color: #757575;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
}

.sort-button:hover {
    background-color: #eeeeee;
}

.tr:last-child .td {
    border-bottom: 0;
}

.add-row {
    color: #9e9e9e;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;
}

.add-row:hover {
    background-color: #f5f5f5;
}

.th {
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
}

.th:hover {
    background-color: #f5f5f5;
}

.th-content {
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    min-height: 36px;
    justify-content: space-between;
}

.td {
    overflow: hidden;
    color: #424242;
    align-items: stretch;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.td-content {
    display: block;
}

.tableClassName {
    border-spacing: 0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.th:last-child {
    border-right: 0;
}

.td:last-child {
    border-right: 0;
}

.resizer {
    display: inline-block;
    background: transparent;
    width: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    cursor: col-resize;
    touch-action: none;
}

.resizer:hover {
    background-color: #8ecae6;
}

.th,
.tr {
    white-space: nowrap;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    position: relative;
}

.text-align-right {
    text-align: right;
}

.cell-padding {
    padding: 0.5rem;
}

.d-flex {
    display: flex;
}

.cursor-default {
    cursor: default;
}

.align-items-center {
    align-items: center;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.border-radius-md {
    border-radius: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.icon-margin {
    margin-right: 4px;
}

.font-weight-600 {
    font-weight: 600;
}

.font-size-75 {
    font-size: 0.75rem;
}

.flex-1 {
    flex: 1;
}

.tr__disabled {
    cursor: pointer;
}

.workOrder__table__header {
    background-color: #fafafa;
    height: 36px;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.table__row__data {
    justify-content: center;
    padding: 0.2rem 0.8rem;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    max-height: 36px;
}

.workOrder__table__row {
    background-color: white;
    font-weight: 500;
}

.workOrder__table__row:hover {
    background-color: #fafafa;
}

.custom__table__filter {
    display: flex;
    justify-content: left;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0.5rem;
    border-radius: 8px 8px 0 0;
    background-color: white;
}

.custom__table__filter__search__container {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: 1ms ease-in;
    background: none;
    background: transparent;
}

.table__filter__input {
    transition: 1ms ease-in;
}

.search__icon {
    font-size: 20px;
}

.table__filter__input {
    border: 1px solid #d9d9d9;
    background: transparent;
}

.table__filter__input:focus {
    border: 1px solid rgb(24, 144, 255);
    outline: none !important;
}

.custom-dropdown .ant-dropdown-menu {
    max-height: 40vh;
    /* Limit maximum height */
    overflow-y: auto;
    /* Enable scrolling */
}