// #stage__dropdown .ant-dropdown-placement-topCenter {
//     position: fixed;
// }

.formulation__dropdown__select>.ant-select-selector {
    max-height: 15rem !important;
    overflow-y: auto !important;
    scrollbar-width: thin !important;
}

.stages__dropdown__select>.ant-select-selector {
    max-height: 8rem !important;
    overflow-y: auto !important;
    scrollbar-width: thin !important;
}

.workorders__dropdown__select>.ant-select-selector {
    max-height: 4rem !important;
    overflow-y: auto !important;
    scrollbar-width: thin !important;
}

.custom-select {
    .ant-select-selector {
        border-radius: 0;
    }
}

.left-button {
    border: 1px solid #d9d9d9;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6.5px 8px;
    height: 32px;
    cursor: pointer;
    box-sizing: border-box;
}

.right-button {
    border: 1px solid #d9d9d9;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 6.5px 8px;
    height: 32px;
    cursor: pointer;
    box-sizing: border-box;
}

.info-text {
    color: #595959;
    font-size: 0.875rem;
}

.circle {
    font-size: 1.875rem;
}

.eq-where {
    math-field {
        display: inline-block;
        width: 120px;
        max-width: 100%;
        min-width: 100%;
        margin: 2px;
        padding: 0;
    }

    math-field::part(container) {
        padding-block: 0;
    }

    math-field::part(menu-toggle) {
        display: none;
    }
}