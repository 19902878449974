.manage-link-btn:hover {
  border-color: #40a9ff !important;
  color: #40a9ff !important;
}

.quality-check-files-table {
  user-select: none;

  .ant-table-cell-row-hover{
    background: #fafafa !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 8px !important;
      border-right: 0px !important;
      border-left: 0px !important;

      font-size: 0.75rem !important;
      background-color: #F5F5F5 !important;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 8px !important;
      border-right: 0px !important;
      font-size: 0.75rem !important;
      height: 35px !important;
    }
  }
}

.quality-check-files-table {
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container {
    height: 100%;
  }
}

.quality-check-files-table-pagination{
  gap: 8px !important;
  .ant-pagination-item{
    font-size: 0.75rem !important;
    border-radius: 4px !important;
  }
}


.quality-check-progress{
  .ant-progress-inner{
    height: 5px !important;

    .ant-progress-bg{
      height: 100% !important;
    }
  }

  .ant-progress-text{
    font-size: 0.625rem !important;
    color: #8C8C8C !important;
  }
}

.quality-check-files-table-header{
  .ant-select-selection-item{
    .ant-typography{
      max-width: 50px !important;
    }
  }
}