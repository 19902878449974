@import '../../../../styles/variables.scss';

.ingredient-template-container {
    height: calc(100vh - 195px);
    .content-container {
      display: flex;
      justify-content: flex-start;
      gap: 16px;
      height: 100%;
      .ingredient-template-sidebar {
        width: 300px;
        background-color: #fafafa;
        position: sticky;
        top: 0;
        height: 100%;
        border-radius: 6px;
        padding: 16px 0;
        padding-bottom: 40px;
        overflow-y: auto;
        .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;
          padding-left: 12px;
          padding-right: 12px;
          .templates {
            font-weight: 600;
          }
        }
        .body {
          margin-top: 20px;
          .ant-collapse {
            background-color: #fafafa;
            .ant-collapse-item:last-child {
              .ant-collapse-content {
                background-color: #fafafa;
                .ant-collapse-content-box {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }
            .ant-collapse-item {
              .ant-collapse-content {
                .ant-collapse-content-box {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }
          }
          .template {
            display: flex;
            justify-content: flex-start;
            gap: 8px;
            cursor: pointer;
            padding: 8px 16px;
          }
          .selected {
            background-color: #e6f7ff;
            border-right: 2px solid #076dd9;
          }
        }
      }
      .template-details {
        flex-grow: 1;
        padding-bottom: 60px;
        overflow-y: auto;
        .header {
          font-size: 1.25rem;
          font-weight: 600;
          display: flex;
          justify-items: flex-start;
          gap: 12px;
          .template-edit {
            cursor: pointer;
          }
        }
        .content {
          margin-top: 20px;
          width: 100%;
          .template-tab-container {
            margin-top: 20px;
            .collapse-header-container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .collapse-header {
                font-size: 0.75rem;
                font-weight: 600;
              }
              .add-new-cta {
                color: $color-primary;
                cursor: pointer;
                font-size: 0.813rem;
                padding: 0 8px;
              }
            }
            .ant-collapse {
              .ant-collapse-item {
                .ant-collapse-content {
                  .ant-collapse-content-box {
                    padding: 0;
                    .data-list {
                      display: flex;
                      justify-content: space-between;
                      font-size: 0.875rem;
                      padding: 16px;
                      border-bottom: 1px solid #d9d9d9;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .repository-table {
    .ant-table-cell {
      padding: 8px 12px!important;
    }
  }
  