.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.editable-cell-td > div.ant-form-item {
    margin: 0 !important;
    width: 100%;
}

.editable-cell-td{
    padding: 4px 11px !important;
}

.editable-table{
    .ant-table{
        font-size: 0.75rem !important;
    }
}