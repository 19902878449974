@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap');

.fontWorkSans {
	* {
		font-family: "Work Sans", sans-serif !important;
		font-style: normal;
	}
}

span.ant-typography strong {
	font-weight: 500;
}

#normal_login_user_location {
	padding-top: 16px;
}

.mainLayoutMargin {
	margin: 48px;
}

.mainLayoutMargin2 {
	margin: 0 20px;
}

.mainLoginLayout {
	background-color: #FAFAFA;
	padding: 55px 40px 28px 72px;
	border-right: 4px solid #F5F5F5;
	border-radius: 0px 25px 25px 0px;
}

.round-text {
	border-radius: 5px !important;
	max-width: 80vw;
	// width: 400px;
}

.password-validator-msg-container {
	display: flex;
	justify-content: center;
	padding: 0;
}

.div80vh {
	min-height: 70vh;
	overflow: auto;
}

.login-form {
	max-width: 50vw;
	width: 600px;

	.ant-row .ant-form-item {
		margin-bottom: 12px !important;
	}

	.ant-form-item {
		margin-bottom: 12px !important;
	}
}

.signup-form {
	max-width: 60vw;
	width: 700px;

	.ant-row .ant-form-item {
		margin-bottom: 12px !important;
	}

	.ant-form-item {
		margin-bottom: 12px !important;
	}
}


.d-flex {
	display: flex !important;
	height: 100vh;
}

.center {
	width: 800px;
}

.login-form-forgot {
	float: right;
}

.ant-col-rtl .login-form-forgot {
	float: left;
}

.login-form-button {
	width: 100%;
	color: #ff9300;
	background-color: #ff9300;
	border-radius: 10px !important;
}

.error-alert-msg {
	.ant-alert-message {
		color: #fff;
	}
}

.primary-btn {
	padding: 14px, 26px, 14px, 26px;
	width: 80%;
	border-radius: 8px;
}

.primary-btn:disabled {
	background: #f5f5f5 !important;
}

.password-validator-msg-container>ul ::marker {
	color: #212121;
}

.list {
	list-style: none;
}

.password-validator-msg-container>ul {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 1px;
	width: 100%;
	list-style: disc;
	font-size: 0.625rem;
	grid-column-gap: 15px;

	.ant-typography {
		font-size: 0.75rem;
	}
}

li {
	text-align: left;
}

.listStyleDisabled {
	padding: 0;
}

.listStyleDisabled>li {
	list-style: none !important;
}

.hide-icons {
	display: none !important;
}

.social-btn:hover {
	background: #d9d9d9 !important;
	color: #212121 !important;
	border-color: #d9d9d9 !important;
}

.round-text>.ant-select-selector {
	height: 48px !important;
	align-items: center !important;
	border-radius: 5px !important;
}

.country>div>span:first-child {
	display: flex !important;
	align-items: center !important;
}

.login-card-container-overflow {
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 0;
		/* Remove scrollbar space */
		background: transparent;
		/* Optional: just make scrollbar invisible */
	}
}

.login-card-container {
	height: 100vh;
	width: 100%;
	margin: 0;
	background-color: #FFF;
}

.color-blue a,
.color-blue span a {
	color: #1753E2;
}

.login-card-container>.ant-card-body {
	padding: 0% !important;
}

.all-right-reserved-container {
	justify-content: space-around;
}

.all-right-reserved {
	position: absolute;
	bottom: 30px;
	color: #8C8C8C;
	font-weight: 500;
	font-size: 0.813rem;
}

.footer-row {
	min-width: 100%;
	// padding-top: 6em;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer-row a {
	color: #1753E2 !important;
}

.ant-space-item:has(> .footer-row) {
	width: 100%;
}

@media screen and (max-width: 1620px) {
	.leftSideOnboardingIllustration {
		width: 20vw;
	}
}

@media screen and (max-width: 1465px) {
	.leftSideOnboardingIllustration {
		width: 14vw;
	}
}

@media screen and (max-width: 1317px) {
	.leftSideOnboardingIllustration {
		width: 0vw;
		display: none;
	}
}

@media screen and (max-width: 1400px) {
	.mainLayoutMargin {
		margin: 10px;
	}

	.mainLayoutMargin2 {
		margin: 10px 5px;
	}
}

@media screen and (max-width: 1000px) {
	.mainLayoutMargin {
		margin: 0px;
	}

	.mainLayoutMargin2 {
		margin: 0;
	}

	.footer-row {
		padding-top: 10px;
	}

	.all-right-reserved {
		grid-column-start: 1;
		grid-column-end: 4;
	}
}


// Custom bullet point 
.labs__feature__info__points {
	padding: 0rem;
}

.labs__feature__info__point__text {
	margin: 0;
	align-items: center;
	font-weight: 400;
}

.labs__feature__info__contaiener {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	gap: 2.25rem;
}

.labs__feature__info__points .labs__feature__info__point {
	padding-left: 33px;
	margin-bottom: 2.5em;
	list-style: none;
}

/* BORDERS AND BULLETS */

.labs__feature__info__points .labs__feature__info__point {
	position: relative;
	/* so that pseudoelements are positioned relatively to their "li"s*/
	/* use padding-bottom instead of margin-bottom.*/
	margin-bottom: 0;
	/* This overrides previously specified margin-bottom */
	padding: 0.5em 2em !important
}

.labs__feature__info__points .labs__feature__info__point:after {
	/* bullets */
	content: "";
	height: 0.4em;
	width: 0.4em;
	background: #F39244;
	display: block;
	position: absolute;
	transform: rotate(45deg);
	top: 50%;
	left: 0;
}

.labs__feature__info__points .labs__feature__info__point:before {
	/* lines */
	content: "";
	position: absolute;
	left: 2.5px;
	/* adjust manually */
	border-left: 1px solid black;
	height: 100%;
	width: 1px;
}



@media screen and (max-width: 600px) {
	.footer-row {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	.all-right-reserved {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}