.suggested-exp-description {
  .ant-descriptions-view{
    border: none!important;

    .ant-descriptions-row{
      border: none!important;

      .ant-descriptions-item-content{
        border: none!important;
        padding-top: 0px!important;
        padding-bottom: 0px!important;
      }

      .ant-descriptions-item-label{
        border: none!important;
        background: transparent!important;
        padding-top: 0px!important;
        padding-bottom: 0px!important;
      }
    }
  }
}

.suggested-exp-note {
  .ant-alert-content {
    line-height: 1;
  }
}

.suggested-exp-result-table{
  .ant-table-title{
    padding: 0 !important;
    padding-bottom: 10px !important;
  }
  .ant-table-thead{
    .ant-table-cell{
        padding: 10px !important;
        border-right: 0px !important;
        text-align: left !important
      }
  }
  .ant-table-tbody{
    .ant-table-cell{
        padding: 10px !important;
        border-right: 0px !important;
      }

      .checkbox-spacing{
        padding-left: 36px !important;
      }

      .checkbox-spacing-remove{
        padding-left: 10px !important;
      }
  }
}

.constraints-table{
  user-select: none;
  .ant-table-thead{
    .ant-table-cell{
        padding: 12px !important;
        border-right: 0px !important;
      }
  }
  .ant-table-tbody{
    .ant-table-cell{
        padding: 12px !important;
        border-right: 0px !important;
      }
  }
}

.action-button-active{
  color: #1677ff !important;
  border-color: #1677ff !important;
}

.predictions-table{
  user-select: none;

  // Done beacuse pagination was also scrolling with table
  .ant-table{
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ant-table-title{
    padding: 0 !important;
    padding-bottom: 10px !important;
  }
  .ant-table-cell-row-hover{
    background: #fafafa !important;
  }
  .ant-table-thead{
    .ant-table-cell{
        padding: 8px 12px !important;
        border-right: 0px !important;
        height: 50px !important;
      }
  }
  .ant-table-tbody{
    .ant-table-cell{
        padding: 8px 12px !important;
        border-right: 0px !important;
        height: 60px !important;
      }
  }
}
.objectives-expandable-table{
  .ant-table-cell-row-hover{
    background: white !important;
  }
}